import {Component, inject, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {map, take, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {RecaptchaComponent} from 'ng-recaptcha';
import {TfUserPasswordService} from '../services/user-password.service';
import {TfAutoUnsubscribeComponent} from '../auto-unsubscribe-component';
import {$localize} from '@angular/localize/init';
import {ToastController} from '@ionic/angular';
import {CODE_CHALLENGE_NAME} from "../app.constants";

@Component({
  selector: 'tf-forgot-password-page',
  styleUrls: ['./forgot-password-page.component.scss'],
  templateUrl: './forgot-password-page.component.html',
})
export class TfForgotPasswordPageComponent extends TfAutoUnsubscribeComponent {
  @ViewChild(RecaptchaComponent)
  public readonly recaptcha!: RecaptchaComponent;

  private readonly formBuilder = inject(FormBuilder)
  private readonly toastController = inject(ToastController)
  private readonly userPasswordService = inject(TfUserPasswordService)
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  public codeQueryParam: string | null = null;

  constructor() {
    super();
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$$)).subscribe(params => {
      this.codeQueryParam = params[CODE_CHALLENGE_NAME]
    })
  }

  public form: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    recaptcha: [null, Validators.required],
  });

  public onSubmitClick(): void {
    if (this.form.hasError('required', 'recaptcha')) {
      this.getCaptchaResult$().subscribe((success) => {
        if (!success) {

          this.toastController.create({message: $localize`:@@errorCaptchaError:Captcha error`}).then(async toast => await toast.present());

          return;
        }

        setTimeout(() => this.sendMail(), 0);
      });
    } else {
      this.sendMail();
    }
  }

  private getCaptchaResult$(): Observable<boolean> {
    const result$ = this.recaptcha.resolved.pipe(map(Boolean), take(1), takeUntil(this.destroy$$));

    this.recaptcha.execute();

    return result$;
  }

  private sendMail(): void {
    if (this.form.hasError('email', 'email')) {

      this.toastController.create({message: $localize`:@@errorEmailInvalid:Error emails invalid`}).then(async toast => await toast.present());
      return;
    }

    this.userPasswordService
      .sendResetLink$(this.form.value)
      .pipe(takeUntil(this.destroy$$))
      .subscribe(
        () => {
          const REDIRECT_TIMEOUT = 3000;

          this.toastController.create({message: $localize`:@@successfulAttempt:Successful attempt`}).then(async toast => await toast.present());

          setTimeout(() => this.router.navigate(["/"], {queryParams: {[CODE_CHALLENGE_NAME]: this.codeQueryParam}}), REDIRECT_TIMEOUT);
        },
        () => {
          this.form.setErrors({badForgotAttempt: true});
        }
      );
  }

  protected readonly window = window;
}
