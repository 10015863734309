import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, inject} from '@angular/core';
import {TfAutoUnsubscribeComponent} from "../auto-unsubscribe-component";
import {ToastController} from '@ionic/angular';
import {TfAccessTokenHttpService} from '../services/acess-token-http.service';
import {CODE_CHALLENGE_NAME, ROUTE_PATHS} from '../app.constants';
import {FORM_CONTROLS} from "./login-page.constants";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'tf-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  host: {class: 'login-page'},
})
export class TfLoginPageComponent extends TfAutoUnsubscribeComponent {
  private readonly accessTokenHttpService = inject(TfAccessTokenHttpService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly toastController = inject(ToastController);
  private readonly activatedRoute = inject(ActivatedRoute);

  public readonly form: FormGroup = this.formBuilder.group({
    [FORM_CONTROLS.EMAIL]: ['', [Validators.required, Validators.email]],
    [FORM_CONTROLS.PASSWORD]: ['', [Validators.required]],
    [FORM_CONTROLS.REMEMBER_ME]: true,
  });

  public isLoading = false;

  public codeQueryParam: string | null = null;
  public forgotPasswordPath: string = `/${ROUTE_PATHS.FORGOT_PASSWORD}`;

  public constructor() {
    super();
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$$)).subscribe(params => {
      this.codeQueryParam = params[CODE_CHALLENGE_NAME]
    })
  }

  public async login(): Promise<void> {
    try {
      this.isLoading = true;

      this.validateFormErrors();
      await this.createUserSession();
    } catch (error: any) {

      this.isLoading = false;
      this.toastController.create({message: error.message}).then(async toast => await toast.present());
    }
  }

  public resetInvalidLoginError(): void {
    const emailControl = this.form.controls[FORM_CONTROLS.EMAIL];
    if (emailControl && emailControl.errors) {
      const {invalidAttempt, ...otherErrors} = emailControl.errors;
      emailControl.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
    }

    const passwordControl = this.form.controls[FORM_CONTROLS.PASSWORD];
    if (passwordControl && passwordControl.errors) {
      const {invalidAttempt, ...otherErrors} = passwordControl.errors;
      passwordControl.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
    }
  }

  private validateFormErrors(): void {
    const emailErrors = this.form.controls[FORM_CONTROLS.EMAIL].errors;

    if (emailErrors) {
      this.form.setErrors(emailErrors);
    }

    if (!this.form.errors) {
      return;
    }

    const errorKeys = Object.keys(this.form.errors);

    throw new Error(errorKeys[0]);
  }

  private async createUserSession(): Promise<void> {
    try {
      const formData = this.form.value;
      const rememberMe = this.form.value.rememberMe;

      await firstValueFrom(this.accessTokenHttpService.create$(formData, rememberMe));
      const response: { status: string; redirect_uri: string } = await firstValueFrom(
        this.accessTokenHttpService.getAuthCode$(this.activatedRoute.snapshot.queryParams[CODE_CHALLENGE_NAME])
      );

      window.location.href = response.redirect_uri;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {

        const message = `Invalid login attempt: ${error.error.message}`;

        this.form.controls[FORM_CONTROLS.EMAIL].setErrors({invalidAttempt: true});
        this.form.controls[FORM_CONTROLS.PASSWORD].setErrors({invalidAttempt: true});

        // @todo update this part to a single catch state
        this.isLoading = false;

        this.toastController.create({message}).then(async (toast) => await toast.present());
      }
    }
  }

  protected readonly window = window;
}
